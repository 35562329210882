<article class="price-matrix" data-zt="priceMatrix" *ngIf="airlineStopsPrices.length > 0">
  <perfect-scrollbar class="ps-show-always">
    <table width="100%">
      <thead>
        <tr>
          <th class="first-column">Todas as cias</th>
          <th class="best-prices"><i class="fas fa-award"></i> Melhores Preços</th>
          <th
            *ngFor="let stopPrices of sortedAirlineStopsPricesFilter"
            class="airline-logo"
            (click)="selectAirline(stopPrices.airline == 'Cias diferentes' ? 'MULTI' : stopPrices.airline)"
          >
            <img *ngIf="stopPrices.airline != 'Cias diferentes'; else multiAirlines" 
              class="airline-logo" src="{{getAirlineInfo(stopPrices.airline).logoUrl}}"
            >
            <ng-template #multiAirlines>
              <div class="multi-airlines">
                <img src="assets/images/multi-cias.svg">
                Cias diferentes
              </div>
            </ng-template>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let rowIndex of [].constructor(maxStopsCount+1); let i = index">
          <td class="first-column">
            <span *ngIf="i == 0">Sem Paradas</span>
            <span *ngIf="i == 1">1 Parada</span>
            <span *ngIf="i > 1">{{i}} Paradas</span>
          </td>
          <td class="best-prices">
            <span (click)="selectPrice(getBestPrice(i), null, i)">{{getBestPrice(i) | currency: "R$"}}</span>
          </td>
          <td class="airline-price" *ngFor="let stopPrices of sortedAirlineStopsPricesFilter; let index = index">
            <span
              [ngClass]="{'font-weight-bold': getBestPrice(i) == stopPrices.getPrice(i).price}"
              class="price-pointer"
              *ngIf="stopPrices.getPrice(i) !== null"
              (click)="selectPrice(stopPrices.getPrice(i).price, stopPrices.airline, i)">
              {{ stopPrices.getPrice(i).price | currency : "R$"}}
            </span>
            <span class="no-price" *ngIf="stopPrices.getPrice(i) == null">
              -
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </perfect-scrollbar>
</article>
<article class="price-matrix" data-zt="priceMatrix" *ngIf="airlineStopsPrices.length == 0">
  <div class="ph-item" *ngFor="let item of [].constructor(4)">
      <article class="loading-container">
          <div>
              <div class="row"></div>
              <div class="row"></div>
              <div class="row"></div>
          </div>
      </article>
  </div>
</article>
