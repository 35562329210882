import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import pt from '@angular/common/locales/pt';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { NgbModule, NgbPopoverConfig, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ZupperDataModule } from '@zupper/data';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
// import { CardModule } from 'ngx-card';
import { QRCodeModule } from 'angularx-qrcode';
import { AddressPayComponent } from './components/address-pay/address-pay.component';
import { AppHeaderProductSearchDirective } from './components/app-header-product/app-header-product-search.directive';
import { AppHeaderProductComponent } from './components/app-header-product/app-header-product.component';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { AppSpinnerComponent } from './components/app-spinner/app-spinner.component';
import { AttentionComponent } from './components/attention/attention.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CardComponent } from './components/card/card.component';
import { DateComponent } from './components/date/date.component';
import { FooterContactsComponent } from './components/footer-contacts/footer-contacts.component';
import { FooterComponent } from './components/footer/footer.component';
import { InfiniteScrollComponent } from './components/infinite-scroll/infinite-scroll.component';
import { InputErrorMessageComponent } from './components/input-error-message/input-error-message.component';
import { ResumePriceComponent } from './components/resume-price/resume-price.component';
import { StopwatchComponent } from './components/stopwatch/stopwatch.component';
import { TelephoneComponent } from './components/telephone/telephone.component';
import { TestimonialsComponent } from './components/testimonials/testimonials.component';
import { TransferComponent } from './components/transfer/transfer.component';
import { ZupperBlogComponent } from './components/zupper-blog/zupper-blog.component';
import { AppClickPreventDefaultDirective } from './directives/app-click-prevent-default.directive';
import { DurationPipe } from './pipes/duration.pipe';
import { KeepHtmlPipe } from './pipes/keep-html.pipe';
import { RealPipe } from './pipes/real.pipe';
import { BannerCarouselComponent } from './components/banner-carousel/banner-carousel.component';
import { TooltipValidationDirective } from './directives/tooltip-validation.directive';
import { ScreenSizeDetectorComponent } from './components/screen-size-detector/screen-size-detector.component';
import { DateSelectionModalComponent } from './components/date-selection-modal/date-selection-modal.component';
import { NgbDateDisplayPipe } from './pipes/ngb-date-display.pipe';
import { DateSelectionComponent } from './components/date-selection/date-selection.component';
import { NgbMomentjsAdapter } from './utils/ngb-date-moment.adapter';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PromosComponent } from './components/promos/promos.component';
import { MainSearchEngineModule } from './components/main-search-engine/main-search-engine.module';
import { ZcFindAirportsModule } from './components/zc-find-airports/zc-find-airports.module';
import { ZcCalendarModule } from './components/zc-calendar/zc-calendar.module';
import { ZcPassengerGuestModule } from './components/zc-passenger-guest/zc-passenger-guest.module';
import { GenericCarouselComponent } from './components/generic-carousel/generic-carousel.component';
import { Ng7BootstrapBreadcrumbModule } from 'ng7-bootstrap-breadcrumb';
import { ZcLoginComponent } from './components/zc-login/zc-login.component';
import { RouterModule } from '@angular/router';
import {
  CUSTOM_ERROR_MESSAGES,
  NgBootstrapFormValidationModule,
} from 'ng-bootstrap-form-validation';
import { CUSTOM_ERRORS } from './models/custom-errors.const';
import { LoginSelectionComponent } from './components/zc-login/steps/login-selection/login-selection.component';
import { EmailLoginComponent } from './components/zc-login/steps/email-login/email-login.component';
import { ZcLoginModalComponent } from './components/zc-login-modal/zc-login-modal.component';
import { FormSubmitOnEnterDirective } from './directives/form-submit-on-enter.directive';
import { ProgressButtonComponent } from './components/progress-button/progress-button.component';
import { ProgressButtonModule } from 'progress-button-zupper';
import { NgSelectModule } from '@ng-select/ng-select';
import { MessageDisplayComponent } from './components/partials/message-display/message-display.component';
import { ZupperHeaderComponent } from './components/zupper-header/zupper-header.component';
import { CustomerMenuComponent } from './components/customer-menu/customer-menu.component';
import { StringMaxLengthPipe } from './pipes/string-max-length.pipe';
import { MomentModule } from 'ngx-moment';
import { PaxTypePipe } from './pipes/pax-type.pipe';
import { SituationPipe } from './pipes/situation.pipe';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxPrintModule } from 'ngx-print';
import { PaymentFormPipe } from './pipes/payment-form.pipe';
import { ThanksAccountRedirectComponent } from './thanks-account-redirect/thanks-account-redirect.component';
import { AngularEmojisModule } from 'angular-emojis';
import { CreditCardLogoPipe } from './pipes/credit-card-logo.pipe';
import { N2brPipe } from './pipes/n2br.pipe';
import { ZcUploadImageComponent } from './components/zc-upload-image/zc-upload-image.component';
import { FileUploadModule } from 'ng2-file-upload';
import { OrderTrackingModalComponent } from './components/order-tracking-modal/order-tracking-modal.component';
import { DetailNumberTravelersComponent } from './components/detail-number-travelers/detail-number-travelers.component';
import { CapsLockDetectorDirective } from './directives/caps-lock-detector.directive';
import { ZupperUiModule } from './ui/components/zupper-ui.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { StorageModule } from './storage/storage.module';
import { STORAGE_KEY_PREFIX } from './tokens/storage-key-prefix.token';
import { EnvironmentInterface } from './models/environment.interface';
import { FooterSocialMediaComponent } from './components/footer-social-media/footer-social-media.component';
import { ZupperLogoComponent } from './components/zupper-logo/zupper-logo.component';
import { ExpiredSessionModalComponent } from './components/expired-session-modal/expired-session-modal.component';
import { SecuritySealComponent } from './components/security-seal/security-seal.component';
import { AerialHeaderMenuComponent } from './components/aerial-header-menu/aerial-header-menu.component';
import { AerialSideMenuComponent } from './components/aerial-side-menu/aerial-side-menu.component';
import { PriceListComponent } from './components/price-list/price-list.component';
import { ModalVoidSearchComponent } from './components/modal-void-search/modal-void-search.component';
import { DocumentPipe } from './pipes/document.pipe';
import { PlaceholderImagePipe } from './pipes/placeholder-image.pipe';
import { SafeBuyMessageComponent } from './components/safe-buy-message/safe-buy-message.component';
import { StringBoldPipe } from './pipes/string-bold.pipe';
import { ConvertLinkPipe } from './pipes/convert-link.pipe';
import { ZcInstallmentComponent } from './components/zc-installment/zc-installment.component';
import { RemoveAccentPipe } from './pipes/remove-accent.pipe';
import { ZcLabelOfferComponent } from './components/zc-label-offer/zc-label-offer.component';
import { ZcOffersComponent } from './components/zc-offers/zc-offers.component';
import { ZcOffersCardComponent } from './components/zc-offers/zc-offers-card/zc-offers-card.component';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { BlockSpecialCharactersDirective } from './directives/block-special-characters.directive';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { ModalCookieComponent } from "./components/modal-cookie/modal-cookie.component";
import { ModalConnectionInternetComponent } from "./components/modal-connection-internet/modal-connection-internet.component";
import { FeedbackModalComponent } from "./components/feedback-modal/feedback-modal.component";
import { BeforeRequiredMarkerDirective } from './directives/before-required-marker.directive';
import { WhatsappTagComponent } from './components/whatsapp-tag/whatsapp-tag.component';
import { DestinationImageUrlPipe } from './pipes/destination-image-url.pipe';
import { ImageFallbackDirective } from './directives/image-fallback.directive';
import { AlertBoxComponent } from './components/alert-box/alert-box.component';
import { SendPasswordCreationLinkComponent } from './components/zc-login/steps/send-password-creation-link/send-password-creation-link.component';
import { AbandonmentModalComponent } from './components/abandonment-modal/abandonment-modal.component';
import { HotelImagesModalComponent } from './components/hotel-images-modal/hotel-images-modal.component';
import { BodyBackgroundDirective } from './directives/body-background.directive';

registerLocaleData(pt, 'pt-BR');

export const firebaseConfig = {
  apiKey: 'AIzaSyDCs1cPpsVhfyXHQnPcG1DRpLcJCS6jfZw',
  authDomain: 'zupperwebsite.firebaseapp.com',
  projectId: 'zupperwebsite',
  storageBucket: 'zupperwebsite.appspot.com',
  messagingSenderId: '1027924238978',
  appId: '1:1027924238978:web:8e6efe379038e86a76ca9b',
  measurementId: 'G-FBJVH15SMW',
};

@NgModule({
  declarations: [
    AppSpinnerComponent,
    AppClickPreventDefaultDirective,
    AlertBoxComponent,
    AppHeaderComponent,
    AppHeaderProductComponent,
    InfiniteScrollComponent,
    InputErrorMessageComponent,
    FooterContactsComponent,
    FooterComponent,
    DurationPipe,
    CardComponent,
    TransferComponent,
    RealPipe,
    AddressPayComponent,
    TelephoneComponent,
    StopwatchComponent,
    ResumePriceComponent,
    AttentionComponent,
    DateComponent,
    BreadcrumbComponent,
    AppHeaderProductSearchDirective,
    ZupperBlogComponent,
    TestimonialsComponent,
    KeepHtmlPipe,
    BannerCarouselComponent,
    TooltipValidationDirective,
    ImageFallbackDirective,
    ScreenSizeDetectorComponent,
    DateSelectionModalComponent,
    HotelImagesModalComponent,
    NgbDateDisplayPipe,
    DateSelectionComponent,
    PromosComponent,
    GenericCarouselComponent,
    ZcLoginComponent,
    LoginSelectionComponent,
    EmailLoginComponent,
    SendPasswordCreationLinkComponent,
    ZcLoginModalComponent,
    FormSubmitOnEnterDirective,
    ProgressButtonComponent,
    MessageDisplayComponent,
    ZupperHeaderComponent,
    ZupperLogoComponent,
    CustomerMenuComponent,
    StringMaxLengthPipe,
    PaxTypePipe,
    SituationPipe,
    PaymentFormPipe,
    DocumentPipe,
    ThanksAccountRedirectComponent,
    CreditCardLogoPipe,
    DestinationImageUrlPipe,
    N2brPipe,
    ZcUploadImageComponent,
    OrderTrackingModalComponent,
    DetailNumberTravelersComponent,
    CapsLockDetectorDirective,
    FooterSocialMediaComponent,
    ZupperLogoComponent,
    ExpiredSessionModalComponent,
    SecuritySealComponent,
    AerialHeaderMenuComponent,
    AerialSideMenuComponent,
    PriceListComponent,
    ModalVoidSearchComponent,
    PlaceholderImagePipe,
    SafeBuyMessageComponent,
    StringBoldPipe,
    ConvertLinkPipe,
    RemoveAccentPipe,
    ZcInstallmentComponent,
    ZcLabelOfferComponent,
    ZcOffersComponent,
    ZcOffersCardComponent,
    BlockSpecialCharactersDirective,
    ModalCookieComponent,
    ModalConnectionInternetComponent,
    FeedbackModalComponent,
    BeforeRequiredMarkerDirective,
    WhatsappTagComponent,
    AlertBoxComponent,
    AbandonmentModalComponent,
    BodyBackgroundDirective
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    ZupperDataModule,
    NgbModule,
    MomentModule,
    CommonModule,
    // CardModule,
    NgxMaskDirective,
    NgxMaskPipe,
    SweetAlert2Module.forRoot(),
    Ng7BootstrapBreadcrumbModule,
    NgBootstrapFormValidationModule,
    NgBootstrapFormValidationModule.forRoot(),
    NgSelectModule,
    FlexLayoutModule,
    NgxUiLoaderModule,
    ContentLoaderModule,
    CarouselModule,
    MainSearchEngineModule,
    ZcFindAirportsModule,
    ZcCalendarModule,
    ZcPassengerGuestModule,
    QRCodeModule,
    ClipboardModule,
    NgxPrintModule,
    NgScrollbarModule,
    ProgressButtonModule.forRoot({
      design: {
        background: '#008c99',
        color: '#ffffff',
        progressInnerBackground: '#005d66',
        radius: 25,
      },
    }),
    AngularEmojisModule,
    FileUploadModule,
    CarouselModule,
    ZupperUiModule,
    StorageModule.forRoot({
      prefix: '',
    }),
    provideFirebaseApp(() => initializeApp(firebaseConfig)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
  ],
  providers: [
    NgbPopoverConfig,
    provideEnvironmentNgxMask(),
    { provide: NgbDateAdapter, useClass: NgbMomentjsAdapter },
  ],

  exports: [
    FlexLayoutModule,
    MainSearchEngineModule,
    NgBootstrapFormValidationModule,
    ContentLoaderModule,
    ProgressButtonModule,
    MomentModule,
    QRCodeModule,
    CarouselModule,
    ZupperUiModule,
    StorageModule,
    NgSelectModule,
    // NgxMaskModule,
    AppSpinnerComponent,
    AlertBoxComponent,
    AppHeaderComponent,
    AppHeaderProductComponent,
    FooterComponent,
    FooterContactsComponent,
    InfiniteScrollComponent,
    InputErrorMessageComponent,
    DurationPipe,
    // CardModule,
    CardComponent,
    TransferComponent,
    RealPipe,
    AddressPayComponent,
    TelephoneComponent,
    StopwatchComponent,
    ResumePriceComponent,
    AttentionComponent,
    DateComponent,
    BreadcrumbComponent,
    ZupperBlogComponent,
    TestimonialsComponent,
    KeepHtmlPipe,
    BannerCarouselComponent,
    TooltipValidationDirective,
    ImageFallbackDirective,
    ScreenSizeDetectorComponent,
    DateSelectionModalComponent,
    HotelImagesModalComponent,
    NgbDateDisplayPipe,
    DateSelectionComponent,
    PromosComponent,
    GenericCarouselComponent,
    ZcLoginComponent,
    ZcLoginModalComponent,
    FormSubmitOnEnterDirective,
    ProgressButtonComponent,
    MessageDisplayComponent,
    ZupperHeaderComponent,
    ZupperLogoComponent,
    CustomerMenuComponent,
    StringMaxLengthPipe,
    PaxTypePipe,
    SituationPipe,
    PaymentFormPipe,
    DocumentPipe,
    ThanksAccountRedirectComponent,
    CreditCardLogoPipe,
    DestinationImageUrlPipe,
    N2brPipe,
    ZcUploadImageComponent,
    OrderTrackingModalComponent,
    DetailNumberTravelersComponent,
    CapsLockDetectorDirective,
    ZupperLogoComponent,
    ExpiredSessionModalComponent,
    SecuritySealComponent,
    AerialHeaderMenuComponent,
    AerialSideMenuComponent,
    PriceListComponent,
    ModalVoidSearchComponent,
    PlaceholderImagePipe,
    SafeBuyMessageComponent,
    StringBoldPipe,
    ConvertLinkPipe,
    ZcInstallmentComponent,
    ZcLabelOfferComponent,
    ZcOffersComponent,
    RemoveAccentPipe,
    BlockSpecialCharactersDirective,
    RecaptchaV3Module,
    ModalCookieComponent,
    ModalConnectionInternetComponent,
    FeedbackModalComponent,
    BeforeRequiredMarkerDirective,
    BodyBackgroundDirective
  ],
  entryComponents: [
    ZcLoginComponent,
    ZcUploadImageComponent,
    OrderTrackingModalComponent,
    ExpiredSessionModalComponent,
    ModalVoidSearchComponent,
  ],
})
export class ZupperCommonModule {
  public static forRoot(
    environment: EnvironmentInterface
  ): ModuleWithProviders<ZupperCommonModule> {
    return {
      ngModule: ZupperCommonModule,
      providers: [
        NgbPopoverConfig,
        { provide: NgbDateAdapter, useClass: NgbMomentjsAdapter },
        {
          provide: CUSTOM_ERROR_MESSAGES,
          useValue: CUSTOM_ERRORS,
          multi: true,
        },
        // {
        //   provide: AUTH_SETTINGS,
        //   useValue: { appVerificationDisabledForTesting: true },
        // },
        { provide: NgbDateAdapter, useClass: NgbMomentjsAdapter },
        { provide: 'env', useValue: environment },
        {
          provide: STORAGE_KEY_PREFIX,
          useValue: environment.tracking.storagePrefix ?? '',
        },
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha.siteKey },
      ],
    };
  }
}
