import { Inject, Injectable } from '@angular/core';
import { ZupperProductModel, ZupperProduct } from './models/zupper-product.enum';
import { ZupperSocialMedia } from './models/zupper-social-media.model';
import { DestinationGuideConfiguration } from './models/configuration';
import { MediaObserver } from '@angular/flex-layout';

@Injectable({
  providedIn: 'root',
})
export class ConstantsService {
  get isMobile(): boolean {
    return this._mediaObserver.isActive(['xs', 'sm']);
  }

  constructor(
    private _mediaObserver: MediaObserver,
    @Inject('env') private env
  ) {}

  headerProducts(destinationGuideConfig?: DestinationGuideConfiguration): ZupperProductModel[] {
    return [
      new ZupperProductModel({
        product: ZupperProduct.AERIAL,
        label: 'Voos',
        icon: 'plane',
        class: '',
        badgeText: '',
        badgeClass: 'hide',
        order: 0,
        url: this.env.aerial.url,
        visible: true,
        active: true,
      }),
      new ZupperProductModel({
        product: ZupperProduct.HOTEL,
        label: 'Hospedagens',
        icon: 'hotel',
        class: '',
        badgeText: '',
        badgeClass: 'hide',
        order: 1,
        url: this.env.hotel.url,
        visible: true,
        active: true,
      }),
      new ZupperProductModel({
        product: ZupperProduct.VEHICLE,
        label: 'Carros',
        icon: 'fa-car-side',
        class: '',
        badgeText: '',
        badgeClass: 'hide',
        order: 2,
        visible: false,
        active: false,
      }),
      new ZupperProductModel({
        product: ZupperProduct.INSURANCE,
        label: 'Seguros',
        icon: 'fa-briefcase-medical',
        class: '',
        badgeText: '',
        badgeClass: 'hide',
        order: 3,
        visible: false,
        active: false,
      }),
      new ZupperProductModel({
        product: ZupperProduct.PACKAGES,
        label: 'Pacotes',
        icon: 'package',
        class: '',
        badgeText: '',
        badgeClass: 'hide',
        order: 4,
        visible: false,
        active: false,
        url: this.env.packages.url,
        image: '',
      }),
    ];
  }

  get socialMedia(): ZupperSocialMedia[] {
    const baseIconUrl = '/assets/img/footer/social';
    return [
      new ZupperSocialMedia({
        name: 'Facebook',
        iconUrl: `${baseIconUrl}/facebook.svg`,
        url: 'https://www.facebook.com/ZupperViagens-198288600201774',
      }),
      new ZupperSocialMedia({
        name: 'Instagram',
        iconUrl: `${baseIconUrl}/instagram.svg`,
        url: 'https://www.instagram.com/zupperviagens/',
      }),
      new ZupperSocialMedia({
        name: 'YouTube',
        iconUrl: `${baseIconUrl}/youtube.svg`,
        url: 'https://www.youtube.com/channel/UCLjvXOQDBBxC5Ond7NPbZTA/featured',
      }),
      new ZupperSocialMedia({
        name: 'Twitter',
        iconUrl: `${baseIconUrl}/twitter.svg`,
        url: 'https://twitter.com/ZupperViagens',
      }),
      new ZupperSocialMedia({
        name: 'LinkedIn',
        iconUrl: `${baseIconUrl}/linked-in.svg`,
        url: 'https://www.linkedin.com/company/zupperviagens/',
      }),
      new ZupperSocialMedia({
        name: 'Pinterest',
        iconUrl: `${baseIconUrl}/pinterest.svg`,
        url: 'https://br.pinterest.com/zupperviagens/',
      }),
      new ZupperSocialMedia({
        name: 'Blog Zupper',
        iconUrl: `${baseIconUrl}/zupper-blog.svg`,
        url: 'https://www.zupper.com.br/blog/',
      }),
    ];
  }
}
