import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  StopPriceFilter,
  StopPriceSelect,
} from '../../../models/aerial-filters.model';
import { FilterAirlinesModel } from '../../../models/api-filter.model';

@Component({
  selector: 'app-price-matrix',
  templateUrl: './price-matrix.component.html',
  styleUrls: ['./price-matrix.component.scss'],
})
export class PriceMatrixComponent implements OnInit {
  @Input() airlineStopsPrices: Array<StopPriceFilter> = [];
  @Input() multiAirlineStopsPrices: StopPriceFilter;
  @Input() airlines: Array<FilterAirlinesModel>;
  @Input() maxStopsCount: number = 0;
  @Input() bestPrices: Array<number> = [];
  @Output() selectedPrice: EventEmitter<StopPriceSelect> = new EventEmitter();
  @Output() selectedAirline: EventEmitter<string> = new EventEmitter();

  containerScroll = document.querySelector('#container-scroll');

  constructor() {}

  ngOnInit(): void {}

  getAirlineInfo(airlineCode: string): FilterAirlinesModel {
    let result = this.airlines.find((p) => p.iataCode === airlineCode);
    return result ? result : new FilterAirlinesModel();
  }

  getBestPrice(index: number) {
    if (!this.bestPrices[index]) {
      return null;
    }
    return this.bestPrices[index];
  }

  selectPrice(price: number, airline: string = null, stopCount: number = null) {
    this.selectedPrice.emit({
      price: price,
      airline: airline,
      stopCount: stopCount,
    });
  }

  selectAirline(airline: string): void {
    this.selectedAirline.emit(airline);
  }

  get sortedAirlineStopsPricesFilter(): StopPriceFilter[] {
    let airlines = [...this.airlineStopsPrices];
    if (this.multiAirlineStopsPrices) {
      airlines.push(this.multiAirlineStopsPrices);
    }

    airlines = airlines.filter(
      (_flight) => !this.checkMultiCias(_flight.airline)
    );    

    return airlines.sort((a, b) => {
      const priceA = this.getLowestPrice(a);
      const priceB = this.getLowestPrice(b);

      if (priceA === null) return 1;
      if (priceB === null) return -1;

      return priceA - priceB;
    });
  }

  private getLowestPrice(stopPriceFilter: StopPriceFilter): number | null {
    let lowestPrice: number | null = null;

    for (let i = 0; i <= this.maxStopsCount; i++) {
      const price = stopPriceFilter.getPrice(i);
      if (price && (lowestPrice === null || price.price < lowestPrice)) {
        lowestPrice = price.price;
      }
    }

    return lowestPrice;
  }

  checkMultiCias(cia: string): boolean {
    return cia.split('.').length > 1 ? true : false;
  }

  isBestPrice(value: number, stopCount: number): boolean {
    return this.bestPrices[stopCount] === value;
  }
}
